import React from "react";
import { useEffect, useState } from "react";
import css from "./App.module.css";

const SecretH1 = ({ textArray }) => {
  return (
    <h1 className={css.sentence}>
      {textArray.map((text, index) => (
        <>
          <span className={css.secret} key={index}>
            {text}
          </span>{" "}
        </>
      ))}
      {"\n"}
    </h1>
  );
};
const SecretH2 = ({ textArray }) => {
  return (
    <h2 className={css.sentence}>
      {textArray.map((text, index) => (
        <>
          <span className={css.secret} key={index}>
            {text}
          </span>{" "}
        </>
      ))}
    </h2>
  );
};
const SecretP = ({ textArray }) => {
  return (
    <p className={css.sentence}>
      {textArray.map((text, index) => (
        <>
          <span className={css.secret} key={index}>
            {text}
          </span>{" "}
        </>
      ))}
    </p>
  );
};

const highlight = (element) => {
  let defaultBG = element.style.backgroundColor;
  let defaultTransition = element.style.transition;

  element.style.transition = "background 0.3s";
  element.style.backgroundColor = "red";

  setTimeout(() => {
    element.style.backgroundColor = defaultBG;
    setTimeout(function () {
      element.style.transition = defaultTransition;
    }, 300);
  }, 300);
};

function App() {
  const [data, setData] = useState(null);
  const [keepRemoving, setKeepRemoving] = useState(true);

  const handleShow = () => {
    // const element = document.getElementsByClassName("secret");
    const element = document.getElementsByTagName("span");
    const random = Math.floor(Math.random() * element.length);
    const selectedElement = element[random];
    const className = selectedElement.classList[0];
    selectedElement.classList.remove(className);
    selectedElement.scrollIntoView({ behavior: "smooth", block: "end" });
    highlight(selectedElement);
    return element.length > 0;
  };

  useEffect(() => {
    fetch("https://us-central1-nosscraper-370321.cloudfunctions.net/NosScraper")
      .then((res) => {
        return res.text();
      })
      .then((data) => {
        data = data.replace(/\[\(|\)\]/gi, "");
        data = data.split("), (");
        setData(data);
      });
  }, []);

  return data == null ? (
    <div className={css.Page}>
      Loading...
      <div className={css.ldsRoller}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <div
      className={css.Page}
      onClick={() => {
        keepRemoving && setKeepRemoving(handleShow());
      }}
    >
      {data.map((item, index) => {
        item = item.split(/, (.*)/s);
        const text = item[1]?.slice(1, -1).replace(/\\'/g, "'");
        const textArray = text?.split(" ");

        switch (item[0]) {
          case "'h1'":
            return <SecretH1 textArray={textArray} key={index} />;
          case "'h2'":
            return <SecretH2 textArray={textArray} key={index} />;
          case "'p'":
            return <SecretP textArray={textArray} key={index} />;
          default:
            return <p>Something went wrong</p>;
        }
      })}
      <div className={css.pageInfo}>
        <p>
          De oorspronkelijke bron van het bovenstaande artikel is van NOS.nl
          (laatste nieuws). Het artikel veranderd naarmate NOS laatste nieuws
          iets publiceert.
        </p>

        <p>
          Het project <i>“Breaking Stories”</i> is creatief ontwikkelt door Luca
          Izarin en technisch uitgevoerd door Olivier Guntenaar.
        </p>

        <p>
          Voor meer informatie over <i>“Breaking Stories”</i>:{" "}
          <a
            href="https://www.instagram.com/breakingstories_/"
            target="_blank"
            rel="noreferrer"
          >
            instagram.com/breakingstories_
          </a>
        </p>

        <p>
          Meer projecten van Luca Izarin zijn te vinden op:{" "}
          <a href="https://lucaizarin.com" target="_blank" rel="noreferrer">
            lucaizarin.com
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
